// INTL START

// languages codes
// export const languages = [ 'ro', 'en', 'gr', 'pl' ];
export const languages = ['ro', 'en', 'cr', 'bg'];

// countries list
export const countryCodes = [
  {
    value: 'ro',
    label: 'Romania'
  },
  {
    value: 'en',
    label: 'United Kingdom'
  },
  {
    value: 'bg',
    label: 'Bulgaria'
  },
  {
    value: 'cr',
    label: 'Croatia'
  },
  // {
  //     value: 'pl',
  //     label: 'Poland'
  // },
];

// languages list
export const languageCodes = [
  {
    value: 'ro',
    label: 'Romana'
  },
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'bg',
    label: 'Bulgaria'
  },
  {
    value: 'cr',
    label: 'Croatia'
  },
  // {
  //     value: 'pl',
  //     label: 'Polish'
  // },
];

export const translations = {
  "Creare oferta - Pereti D - Simplu Placat": 'create_offer_walls_d_simple',
  "Creare oferta - Pereti D - Dublu Placat": 'create_offer_walls_d_double',
  "Creare oferta - Pereti D - Triplu Placat": 'create_offer_walls_d_triple',
  "Creare oferta - Pereti S - Dublu Placat": 'create_offer_walls_s_double',
  "Creare oferta - Pereti S - Triplu Placat": 'create_offer_walls_s_triple',
  "Creare oferta - Pereti S - Asimetrici": 'create_offer_walls_s_asimetric',
  "Creare oferta - Pereti S - Dublu Intermediar": 'create_offer_walls_s_double_intermediate',
  "Creare oferta - Pereti S - Triplu Intermediar": 'create_offer_walls_s_triple_intermediate',
  "Creare oferta - Pereti SL - Simplu Placat": 'create_offer_walls_sl_simple',
  "Creare oferta - Pereti SL - Dublu Placat": 'create_offer_walls_sl_double',
  "Creare oferta - Pereti SL - Triplu Placat": 'create_offer_walls_sl_triple',
  "Creare oferta - Pereti SLA - Triplu Placat": 'create_offer_walls_sla_triple',
  "Creare oferta - Placari cu fixari - Simple": 'create_offer_plating_fix_simple',
  "Creare oferta - Placari cu fixari - Duble": 'create_offer_plating_fix_double',
  "Creare oferta - Placari cu fixari - Triple": 'create_offer_plating_fix_triple',
  "Creare oferta - Placari cu fixari - Cvadruple": 'create_offer_plating_fix_quadruple',
  "Creare oferta - Placari cu fixari - EI180 min (5 placi)": 'create_offer_plating_fix_ei',
  "Creare oferta - Placari independente - Simple": 'create_offer_plating_indep_simple',
  "Creare oferta - Placari independente - Duble": 'create_offer_plating_indep_double',
  "Creare oferta - Placari independente - Triple": 'create_offer_plating_indep_triple',
  "Creare oferta - Placari independente - Cvadruple": 'create_offer_plating_indep_quadruple',
  "Creare oferta - Placari independente - EI180 min (5 placi)": 'create_offer_plating_indep_ei',
  "Creare oferta - Placari liniare - Duble": 'create_offer_plating_liniar_double',
  "Creare oferta - Placari liniare - Triple": 'create_offer_plating_liniar_triple',
  "Creare oferta - Placari liniare - Cvadruple": 'create_offer_plating_liniar_quadruple',
  "Creare oferta - Placari Lipire": 'create_offer_plating_sticking',
  "Creare oferta - Placari Noisy cu fixari - Triple": 'create_offer_noisy_plating_fix_triple',
  "Creare oferta - Placari Noisy independente - Duble": 'create_offer_noisy_plating_indep_double',
  "Creare oferta - Placari Noisy independente - Triple": 'create_offer_noisy_plating_indep_triple',
  "Creare oferta - Placari Noisy UU - Duble": 'create_offer_noisy_plating_uu_double',
  "Creare oferta - Placari Noisy UU - Triple": 'create_offer_noisy_plating_uu_triple',
  "Creare oferta - Plafoane Suspendate - Simple": 'create_offer_ceiling_suspended_simple',
  "Creare oferta - Plafoane Suspendate - Duble": 'create_offer_ceiling_suspended_double',
  "Creare oferta - Plafoane Suspendate - Triple": 'create_offer_ceiling_suspended_triple',
  "Creare oferta - Plafoane Suspendate - Cvadruple": 'create_offer_ceiling_suspended_quadruple',
  "Creare oferta - Plafoane Autoportante - Simple": 'create_offer_ceiling_autoport_simple',
  "Creare oferta - Plafoane Autoportante - Duble": 'create_offer_ceiling_autoport_double',
  "Creare oferta - Plafoane Autoportante - Triple": 'create_offer_ceiling_autoport_triple',
  "Creare oferta - Plafoane Autoportante - Cvadruple": 'create_offer_ceiling_autoport_quadruple',
  "Creare oferta - Pereti Smart - Simplu Placat": 'create_offer_walls_smart_simple',
  "Creare oferta - Pereti Smart - Dublu Placat": 'create_offer_walls_smart_double',
  "Creare oferta - Placari Smart cu fixari - Simple": 'create_offer_plating_smart_fix_simple',
  "Creare oferta - Placari Smart cu fixari - Duble": 'create_offer_plating_smart_fix_double',
  "Creare oferta - Placari Smart independente - Simple": 'create_offer_plating_smart_indep_simple',
  "Creare oferta - Placari Smart independente - Duble": 'create_offer_plating_smart_indep_double',
  "Creare oferta - Plafoane Smart Suspendate - Simple": 'create_offer_ceiling_smart_suspended_simple',
  "Creare oferta - Plafoane Smart Suspendate - Duble": 'create_offer_ceiling_smart_suspended_double',
  "Creare oferta - Plafoane Smart Autoportante - Simple": 'create_offer_ceiling_smart_autoport_simple',
  "Creare oferta - Plafoane Smart Autoportante - Duble": 'create_offer_ceiling_smart_autoport_double',
  "Creare oferta - Placari Smart Lipire": 'create_offer_plating_smart_sticking'
}

export const translationsDetails = {
  "Creare oferta - Pereti D - Simplu Placat": 'walls_d_simple',
  "Creare oferta - Pereti D - Dublu Placat": 'walls_d_double',
  "Creare oferta - Pereti D - Triplu Placat": 'walls_d_triple',
  "Creare oferta - Pereti S - Dublu Placat": 'walls_s_double',
  "Creare oferta - Pereti S - Triplu Placat": 'walls_s_triple',
  "Creare oferta - Pereti S - Asimetrici": 'walls_s_asimetric',
  "Creare oferta - Pereti S - Dublu Intermediar": 'walls_s_double_intermediate',
  "Creare oferta - Pereti S - Triplu Intermediar": 'walls_s_triple_intermediate',
  "Creare oferta - Pereti SL - Simplu Placat": 'walls_sl_simple',
  "Creare oferta - Pereti SL - Dublu Placat": 'walls_sl_double',
  "Creare oferta - Pereti SL - Triplu Placat": 'walls_sl_triple',
  "Creare oferta - Pereti SLA - Triplu Placat": 'walls_sla_triple',
  "Creare oferta - Placari cu fixari - Simple": 'plating_fix_simple',
  "Creare oferta - Placari cu fixari - Duble": 'plating_fix_double',
  "Creare oferta - Placari cu fixari - Triple": 'plating_fix_triple',
  "Creare oferta - Placari cu fixari - Cvadruple": 'plating_fix_quadruple',
  "Creare oferta - Placari cu fixari - EI180 min (5 placi)": 'plating_fix_ei',
  "Creare oferta - Placari independente - Simple": 'plating_indep_simple',
  "Creare oferta - Placari independente - Duble": 'plating_indep_double',
  "Creare oferta - Placari independente - Triple": 'plating_indep_triple',
  "Creare oferta - Placari independente - Cvadruple": 'plating_indep_quadruple',
  "Creare oferta - Placari independente - EI180 min (5 placi)": 'plating_indep_ei',
  "Creare oferta - Placari liniare - Duble": 'plating_liniar_double',
  "Creare oferta - Placari liniare - Triple": 'plating_liniar_triple',
  "Creare oferta - Placari liniare - Cvadruple": 'plating_liniar_quadruple',
  "Creare oferta - Placari Lipire": 'plating_sticking',
  "Creare oferta - Placari Noisy cu fixari - Triple": 'noisy_plating_fix_triple',
  "Creare oferta - Placari Noisy independente - Duble": 'noisy_plating_indep_double',
  "Creare oferta - Placari Noisy independente - Triple": 'noisy_plating_indep_triple',
  "Creare oferta - Placari Noisy UU - Duble": 'noisy_plating_uu_double',
  "Creare oferta - Placari Noisy UU - Triple": 'noisy_plating_uu_triple',
  "Creare oferta - Plafoane Suspendate - Simple": 'ceiling_suspended_simple',
  "Creare oferta - Plafoane Suspendate - Duble": 'ceiling_suspended_double',
  "Creare oferta - Plafoane Suspendate - Triple": 'ceiling_suspended_triple',
  "Creare oferta - Plafoane Suspendate - Cvadruple": 'ceiling_suspended_quadruple',
  "Creare oferta - Plafoane Autoportante - Simple": 'ceiling_autoport_simple',
  "Creare oferta - Plafoane Autoportante - Duble": 'ceiling_autoport_double',
  "Creare oferta - Plafoane Autoportante - Triple": 'ceiling_autoport_triple',
  "Creare oferta - Plafoane Autoportante - Cvadruple": 'ceiling_autoport_quadruple',
  "Creare oferta - Pereti Smart - Simplu Placat": 'walls_smart_simple',
  "Creare oferta - Pereti Smart - Dublu Placat": 'walls_smart_double',
  "Creare oferta - Placari Smart cu fixari - Simple": 'plating_smart_fix_simple',
  "Creare oferta - Placari Smart cu fixari - Duble": 'plating_smart_fix_double',
  "Creare oferta - Placari Smart independente - Simple": 'plating_smart_indep_simple',
  "Creare oferta - Placari Smart independente - Duble": 'plating_smart_indep_double',
  "Creare oferta - Plafoane Smart Suspendate - Simple": 'ceiling_smart_suspended_simple',
  "Creare oferta - Plafoane Smart Suspendate - Duble": 'ceiling_smart_suspended_double',
  "Creare oferta - Plafoane Smart Autoportante - Simple": 'ceiling_smart_autoport_simple',
  "Creare oferta - Plafoane Smart Autoportante - Duble": 'ceiling_smart_autoport_double',
  "Creare oferta - Placari Smart Lipire": 'plating_smart_sticking',
}

export const mineralWoolOptions = ['vat', 'mineral', 'wool', 'минерална'];

const countiesRo = ['Alba', 'Arad', 'Arges', 'Bacau', 'Bihor', 'Bistrita-Nasaud', 'Botosani', 'Brasov', 'Braila', 'Bucuresti', 'Buzau', 'Caras-Severin', 'Calarasi', 'Cluj', 'Constanta', 'Covasna', 'Dambovita', 'Dolj', 'Galati', 'Giurgiu', 'Gorj', 'Harghita', 'Hunedoara', 'Ialomita', 'Iasi', 'Ilfov', 'Maramures', 'Mehedinti', 'Mures', 'Neamt', 'Olt', 'Prahova', 'Satu Mare', 'Salaj', 'Sibiu', 'Suceava', 'Teleorman', 'Timis', 'Tulcea', 'Vaslui', 'Valcea', 'Vrancea'];
const countiesEn = ['xxx_Alba', 'xxx_Arad', 'xxx_Arges', 'xxx_Bacau', 'xxx_Bihor', 'xxx_Bistrita-Nasaud', 'xxx_Botosani', 'xxx_Brasov', 'xxx_Braila', 'xxx_Bucuresti', 'xxx_Buzau', 'xxx_Caras-Severin', 'xxx_Calarasi', 'xxx_Cluj', 'xxx_Constanta', 'xxx_Covasna', 'xxx_Dambovita', 'xxx_Dolj', 'xxx_Galati', 'xxx_Giurgiu', 'xxx_Gorj', 'xxx_Harghita', 'xxx_Hunedoara', 'xxx_Ialomita', 'xxx_Iasi', 'xxx_Ilfov', 'xxx_Maramures', 'xxx_Mehedinti', 'xxx_Mures', 'xxx_Neamt', 'xxx_Olt', 'xxx_Prahova', 'xxx_Satu Mare', 'xxx_Salaj', 'xxx_Sibiu', 'xxx_Suceava', 'xxx_Teleorman', 'xxx_Timis', 'xxx_Tulcea', 'xxx_Vaslui', 'xxx_Valcea', 'xxx_Vrancea'];
// const countiesGr = [ 'ggg_Alba', 'ggg_Arad', 'ggg_Arges', 'ggg_Bacau', 'ggg_Bihor', 'ggg_Bistrita-Nasaud', 'ggg_Botosani', 'ggg_Brasov', 'ggg_Braila', 'ggg_Bucuresti', 'ggg_Buzau', 'ggg_Caras-Severin', 'ggg_Calarasi', 'ggg_Cluj', 'ggg_Constanta', 'ggg_Covasna', 'ggg_Dambovita', 'ggg_Dolj', 'ggg_Galati', 'ggg_Giurgiu', 'ggg_Gorj', 'ggg_Harghita', 'ggg_Hunedoara', 'ggg_Ialomita', 'ggg_Iasi', 'ggg_Ilfov', 'ggg_Maramures', 'ggg_Mehedinti', 'ggg_Mures', 'ggg_Neamt', 'ggg_Olt', 'ggg_Prahova', 'ggg_Satu Mare', 'ggg_Salaj', 'ggg_Sibiu', 'ggg_Suceava', 'ggg_Teleorman', 'ggg_Timis', 'ggg_Tulcea', 'ggg_Vaslui', 'ggg_Valcea', 'ggg_Vrancea' ];
// const countiesPl = [ 'ppp_Alba', 'ppp_Arad', 'ppp_Arges', 'ppp_Bacau', 'ppp_Bihor', 'ppp_Bistrita-Nasaud', 'ppp_Botosani', 'ppp_Brasov', 'ppp_Braila', 'ppp_Bucuresti', 'ppp_Buzau', 'ppp_Caras-Severin', 'ppp_Calarasi', 'ppp_Cluj', 'ppp_Constanta', 'ppp_Covasna', 'ppp_Dambovita', 'ppp_Dolj', 'ppp_Galati', 'ppp_Giurgiu', 'ppp_Gorj', 'ppp_Harghita', 'ppp_Hunedoara', 'ppp_Ialomita', 'ppp_Iasi', 'ppp_Ilfov', 'ppp_Maramures', 'ppp_Mehedinti', 'ppp_Mures', 'ppp_Neamt', 'ppp_Olt', 'ppp_Prahova', 'ppp_Satu Mare', 'ppp_Salaj', 'ppp_Sibiu', 'ppp_Suceava', 'ppp_Teleorman', 'ppp_Timis', 'ppp_Tulcea', 'ppp_Vaslui', 'ppp_Valcea', 'ppp_Vrancea' ];

// counties
export const counties = {
  ro: countiesRo,
  en: countiesEn,
  // gr: countiesGr,
  // pl: countiesPl
};

// jobs
const workRo = ['Distribuitor Siniat', 'Revanzator Siniat', 'Constructor Specializat', 'Constructor General', 'Montator Specializat', 'Birou de Arhitectura', 'Arhitect', 'Persoana Fizica'];
const workEn = ['Distributor Siniat', 'Reseller Siniat', 'Specialized Builder', 'General Builder', 'Specialized fitter', 'Architecture office', 'Arhitect', 'Individual'];
const workCr = ['Potpisani distributer', 'Preprodavač Siniat', 'Specijalizirani graditelj', 'Generalni graditelj', 'Specijalizirani monter', 'Arhitektonski ured', 'Arhitekt', 'Pojedinac'];
const workBg = ['Дистрибутор на Siniat', 'Преотстъпник на Siniat', 'Специализиран Строител', 'Генерален Строител', 'Специализиран монтажник', 'Архитектурно Бюро', 'Архитект', 'Физическо лице'];
// const workGr = [ 'ggg_Distribuitor Siniat', 'ggg_Revanzator Siniat', 'ggg_Constructor Specializat', 'ggg_Constructor General', 'ggg_Montator Specializat', 'ggg_Birou de Arhitectura', 'ggg_Arhitect', 'ggg_Persoana Fizica' ];
// const workPl = [ 'ppp_Distribuitor Siniat', 'ppp_Revanzator Siniat', 'ppp_Constructor Specializat', 'ppp_Constructor General', 'ppp_Montator Specializat', 'ppp_Birou de Arhitectura', 'ppp_Arhitect', 'ppp_Persoana Fizica' ];
export const jobs = {
  ro: workRo,
  en: workEn,
  bg: workBg,
  cr: workCr,
  // gr : workGr,
  // pl : workPl
}

// INTL END

export const langs = ['ro', 'en'];

export const statesRo = ['Alba', 'Arad', 'Arges', 'Bacau', 'Bihor', 'Bistrita-Nasaud', 'Botosani', 'Brasov', 'Braila', 'Bucuresti', 'Buzau', 'Caras-Severin', 'Calarasi', 'Cluj', 'Constanta', 'Covasna', 'Dambovita', 'Dolj', 'Galati', 'Giurgiu', 'Gorj', 'Harghita', 'Hunedoara', 'Ialomita', 'Iasi', 'Ilfov', 'Maramures', 'Mehedinti', 'Mures', 'Neamt', 'Olt', 'Prahova', 'Satu Mare', 'Salaj', 'Sibiu', 'Suceava', 'Teleorman', 'Timis', 'Tulcea', 'Vaslui', 'Valcea', 'Vrancea'];

export const jobsRo = ['Distribuitor Siniat', 'Revanzator Siniat', 'Constructor Specializat', 'Constructor General', 'Montator Specializat', 'Birou de Arhitectura', 'Arhitect', 'Persoana Fizica'];

export const statesEn = ['Essex', 'Leicester', 'London', 'Surrey',];

export const jobsEn = ['Distributor Siniat', 'Reseller Siniat', 'Specialized Builder', 'General Builder', 'Specialized fitter', 'Architecture office', 'Arhitect', 'Individual'];

export const phoneNumberPattern = /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|-)?([0-9]{3}(\s|\.|-|)){2}$/igm;

export const internationalPhoneNumberPattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export const floatNumberRegex = /^[+-]?\d+(\.\d+)?$/;

export const foodChoices = ['herbivorous', 'carnivorous', 'omnivorous'];

export const linkTexts = {
  // walls

  walls_d_booklet: 'https://media.siniat.ro/pi38774/original/brosura-pereti-gips-carton-inalti-siniat.pdf',
  walls_d_booklet_label: 'Brosura pereti',
  walls_d_plates_s_pdf: 'https://media.siniat.ro/pi317428/original/nida-system-d2x1---detalii-generale-072021.pdf',
  walls_d_plates_s_pdf_label: 'pereti simplu placati',
  walls_d_plates_s_dwg: 'https://media.siniat.ro/pi317564/original/perete-tip-d-simplu-placat---detalii-cad.dwg',
  walls_d_plates_s_dwg_label: 'pereti simplu placati',
  walls_d_plates_d_pdf: 'https://media.siniat.ro/pi317430/original/nida-system-d2x2---detalii-tehnice-082021.pdf',
  walls_d_plates_d_pdf_label: 'pereti dublu placati',
  walls_d_plates_d_dwg: 'https://media.siniat.ro/pi317563/original/perete-tip-d-dublu-placat---detalii-cad.dwg',
  walls_d_plates_d_dwg_label: 'pereti dublu placati',
  walls_d_plates_t_pdf: 'https://media.siniat.ro/pi317452/original/nida-system-d2x3---detalii-tehnice-082021.pdf',
  walls_d_plates_t_pdf_label: 'pereti triplu placati',
  walls_d_plates_t_dwg: 'https://media.siniat.ro/pi317548/original/perete-tip-d-triplu-placat---detalii-cad.dwg',
  walls_d_plates_t_dwg_label: 'pereti triplu placati',

  walls_s_booklet: 'https://media.siniat.ro/pi38774/original/brosura-pereti-gips-carton-inalti-siniat.pdf',
  walls_s_booklet_label: 'Brosura pereti',
  walls_s_plates_d_pdf: 'https://media.siniat.ro/pi350813/original/2001396548/perete-s2.0---detalii-tehnice.pdf',
  walls_s_plates_d_pdf_label: 'pereti S dublu placati',
  walls_s_plates_d_dwg: 'https://media.siniat.ro/pi350808/original/758566040/perete-s2.0---detalii-cad.dwg',
  walls_s_plates_d_dwg_label: 'pereti S dublu placati',
  walls_s_plates_t_pdf: 'https://media.siniat.ro/pi350812/original/536598756/perete-s3.0---detalii-tehnice.pdf',
  walls_s_plates_t_pdf_label: 'pereti S triplu placati',
  walls_s_plates_t_dwg: 'https://media.siniat.ro/pi350812/original/536598756/perete-s3.0---detalii-tehnice.pdf',
  walls_s_plates_t_dwg_label: 'pereti S triplu placati',
  walls_s_asimetric_pdf: 'https://media.siniat.ro/pi350810/original/-2082782129/perete-s2.3.0---detalii-tehnice.pdf',
  walls_s_asimetric_pdf_label: 'pereti S asimetrici',
  walls_s_asimetric_dwg: 'https://media.siniat.ro/pi350805/original/-840206445/perete-s2.3.0---detalii-cad.dwg',
  walls_s_asimetric_dwg_label: 'pereti S asimetrici',
  walls_s_intermediar_plates_d_pdf: 'https://media.siniat.ro/pi350807/original/-1537405278/perete-s2.1---detalii-tehnice.pdf',
  walls_s_intermediar_plates_d_pdf_label: 'pereti S dublu placati si placa intermediara',
  walls_s_intermediar_plates_d_dwg: 'https://media.siniat.ro/pi350806/original/-759062158/perete-s2.1---detalii-cad.dwg',
  walls_s_intermediar_plates_d_dwg_label: 'pereti S dublu placati si placa intermediara',
  walls_s_intermediar_plates_t_pdf: 'https://media.siniat.ro/pi350811/original/-759133900/perete-s3.1---detalii-tehnice.pdf',
  walls_s_intermediar_plates_t_pdf_label: 'pereti S triplu placati si placa intermediara',
  walls_s_intermediar_plates_t_dwg: 'https://media.siniat.ro/pi350809/original/perete-s3.1---detalii-cad.dwg',
  walls_s_intermediar_plates_t_dwg_label: 'pereti S triplu placati si placa intermediara',

  walls_sl_booklet: 'https://media.siniat.ro/pi38774/original/brosura-pereti-gips-carton-inalti-siniat.pdf',
  walls_sl_booklet_label: 'Brosura pereti',
  // walls_sl_plates_s_pdf: '',
  // walls_sl_plates_s_pdf_label: '',
  // walls_sl_plates_s_dwg: '',
  // walls_sl_plates_s_dwg_label: '',
  walls_sl_plates_d_pdf: 'https://media.siniat.ro/pi316935/original/perete-separativ-dublu-placat---detalii-tehnice-72021bis.pdf',
  walls_sl_plates_d_pdf_label: 'pereti SL dublu placati',
  walls_sl_plates_d_dwg: 'https://media.siniat.ro/pi315650/original/perete-separativ-dublu-placat-detalii-cad-siniat-072021.dwg',
  walls_sl_plates_d_dwg_label: 'pereti SL dublu placati',
  walls_sl_plates_t_pdf: 'https://media.siniat.ro/pi315711/original/siniat_perete_sl2x3_triplu_placat-detalii_generale.pdf?v=-1641161671',
  walls_sl_plates_t_pdf_label: 'pereti SL triplu placati',
  walls_sl_plates_t_dwg: 'https://media.siniat.ro/pi315651/original/siniat_perete_sl2x3_triplu_placat-detalii_generale.dwg?v=-1641161671',
  walls_sl_plates_t_dwg_label: 'pereti SL triplu placati',

  walls_sla_booklet: 'https://media.siniat.ro/pi38774/original/brosura-pereti-gips-carton-inalti-siniat.pdf',
  walls_sla_booklet_label: 'Brosura pereti',
  // walls_sla_plates_t_pdf: '',
  walls_sla_plates_t_pdf_label: 'pereti SLA triplu placati',
  // walls_sla_plates_t_dwg: '',
  walls_sla_plates_t_dwg_label: 'pereti SLA triplu placati',

  // linnings

  linnings_f_booklet: 'https://media.siniat.ro/pi38772/original/brosura-placari-2020-web.pdf?v=1020935976',
  linnings_f_booklet_label: 'Brosura placari',
  linnings_f_profile_cd_plates_s_pdf: 'https://media.siniat.ro/pi324906/original/placari-nida-system-t1.cd-ei---detalii-tehnice.pdf',
  linnings_f_profile_cd_plates_s_pdf_label: 'placari cu fixari simple',
  linnings_f_profile_cd_plates_s_dwg: 'https://media.siniat.ro/pi324819/original/nida-system-t1.cd-fix-ei---detalii-cad.dwg',
  linnings_f_profile_cd_plates_s_dwg_label: 'placari cu fixari simple',
  linnings_f_profile_cd_plates_d_pdf: 'https://media.siniat.ro/pi324813/original/placari-nida-system-t2.cd-fix-ei---detalii-tehnice.pdf',
  linnings_f_profile_cd_plates_d_pdf_label: 'placari cu fixari duble',
  linnings_f_profile_cd_plates_d_dwg: 'https://media.siniat.ro/pi324823/original/nida-system-t2.cd-fix-ei---detalii-cad.dwg',
  linnings_f_profile_cd_plates_d_dwg_label: 'placari cu fixari duble',
  linnings_f_profile_cd_plates_t_pdf: 'https://media.siniat.ro/pi324811/original/placari-nida-system-t3.cd-fix-ei---detalii-tehnice.pdf',
  linnings_f_profile_cd_plates_t_pdf_label: 'placari cu fixari triple',
  linnings_f_profile_cd_plates_t_dwg: 'https://media.siniat.ro/pi324822/original/nida-system-t3.cw.f-ei---detalii-cad.dwg',
  linnings_f_profile_cd_plates_t_dwg_label: 'placari cu fixari triple',
  linnings_f_profile_cd_plates_q_pdf: 'https://media.siniat.ro/pi324814/original/placari-nida-system-t4.cd-fix-ei---detalii-tehnice.pdf',
  linnings_f_profile_cd_plates_q_pdf_label: 'placari cu fixari qvadruple',
  linnings_f_profile_cd_plates_q_dwg: 'https://media.siniat.ro/pi324824/original/nida-system-t4.cd-fix-ei---detalii-cad.dwg',
  linnings_f_profile_cd_plates_q_dwg_label: 'placari cu fixari qvadruple',
  linnings_f_profile_cw_plates_s_pdf: 'https://media.siniat.ro/pi324804/original/placari-nida-system-t1.cw.f---detalii-tehnice.pdf',
  linnings_f_profile_cw_plates_s_pdf_label: 'placari cu fixari simple',
  linnings_f_profile_cw_plates_s_dwg: 'https://media.siniat.ro/pi324815/original/nida-system-t1.cw.f-ei---detalii-cad.dwg',
  linnings_f_profile_cw_plates_s_dwg_label: 'placari cu fixari simple',
  linnings_f_profile_cw_plates_d_pdf: 'https://media.siniat.ro/pi324810/original/placari-nida-system-t2.cw.f---detalii-tehnice.pdf',
  linnings_f_profile_cw_plates_d_pdf_label: 'placari cu fixari duble',
  linnings_f_profile_cw_plates_d_dwg: 'https://media.siniat.ro/pi324821/original/nida-system-t2.cw.f---detalii-cad.dwg?v=-1945928931',
  linnings_f_profile_cw_plates_d_dwg_label: 'placari cu fixari duble',
  linnings_f_profile_cw_plates_t_pdf: 'https://media.siniat.ro/pi324812/original/placari-nida-system-t3.cw.f-ei---detalii-tehnice.pdf',
  linnings_f_profile_cw_plates_t_pdf_label: 'placari cu fixari triple',
  linnings_f_profile_cw_plates_t_dwg: 'https://media.siniat.ro/pi324822/original/nida-system-t3.cw.f-ei---detalii-cad.dwg',
  linnings_f_profile_cw_plates_t_dwg_label: 'placari cu fixari triple',
  linnings_f_profile_cw_plates_q_pdf: 'https://media.siniat.ro/pi324808/original/placari-nida-system-t4.cw.f-ei---detalii-tehnice.pdf',
  linnings_f_profile_cw_plates_q_pdf_label: 'placari cu fixari qvadruple',
  linnings_f_profile_cw_plates_q_dwg: 'https://media.siniat.ro/pi324818/original/nida-system-t4.cw.f-ei---detalii-cad.dwg',
  linnings_f_profile_cw_plates_q_dwg_label: 'placari cu fixari qvadruple',

  linnings_i_booklet: 'https://media.siniat.ro/pi38772/original/brosura-placari-2020-web.pdf?v=1020935976',
  linnings_i_booklet_label: 'Brosura placari',
  linnings_i_plates_s_pdf: 'https://media.siniat.ro/pi324907/original/placari-nida-system-t1.cw.i-ei---detalii-tehnice.pdf',
  linnings_i_plates_s_pdf_label: 'placari independente simple',
  linnings_i_plates_s_dwg: 'https://media.siniat.ro/pi324877/original/nida-system-t1.cw.i-ei---detalii-cad.dwg',
  linnings_i_plates_s_dwg_label: 'placari independente simple',
  linnings_i_plates_d_pdf: 'https://media.siniat.ro/pi324904/original/placari-nida-system-t2.cw.i---detalii-tehnice.pdf',
  linnings_i_plates_d_pdf_label: 'placari independente duble',
  linnings_i_plates_d_dwg: 'https://media.siniat.ro/pi324881/original/nida-system-t2.cw.i-ei---detalii-cad.dwg',
  linnings_i_plates_d_dwg_label: 'placari independente duble',
  linnings_i_plates_t_pdf: 'https://media.siniat.ro/pi324905/original/placari-nida-system-t3.cw.i-ei---detalii-tehnice.pdf',
  linnings_i_plates_t_pdf_label: 'placari independente triple',
  linnings_i_plates_t_dwg: 'https://media.siniat.ro/pi324870/original/nida-system-t3.cw.i-ei---detalii-cad.dwg',
  linnings_i_plates_t_dwg_label: 'placari independente triple',
  linnings_i_plates_q_pdf: 'https://media.siniat.ro/pi324910/original/placari-nida-system-t4.cw.i-ei---detalii-tehnice.pdf',
  linnings_i_plates_q_pdf_label: 'placari independente qvadruple',
  linnings_i_plates_q_dwg: 'https://media.siniat.ro/pi324873/original/nida-system-t4.cw.i-ei---detalii-cad.dwg',
  linnings_i_plates_q_dwg_label: 'placari independente qvadruple',

  linnings_l_booklet: 'https://media.siniat.ro/pi38772/original/brosura-placari-2020-web.pdf?v=1020935976',
  linnings_l_booklet_label: 'Brosura placari',
  linnings_l_plates_d_pdf: 'https://media.siniat.ro/pi324802/original/placari-nida-system-t2.w25---detalii-tehnice.pdf',
  linnings_l_plates_d_pdf_label: 'placari liniare duble',
  linnings_l_plates_d_dwg: 'https://media.siniat.ro/pi324798/original/placari-nida-system-t2.w25.dwg',
  linnings_l_plates_d_dwg_label: 'placari liniare duble',
  linnings_l_plates_t_pdf: 'https://media.siniat.ro/pi324803/original/placari-nida-system-t3.w25---detalii-tehnice.pdf',
  linnings_l_plates_t_pdf_label: 'placari liniare triple',
  linnings_l_plates_t_dwg: 'https://media.siniat.ro/pi324799/original/placari-nida-system-t3.w25.dwg',
  linnings_l_plates_t_dwg_label: 'placari liniare triple',
  linnings_l_plates_q_pdf: 'https://media.siniat.ro/pi324801/original/placari-nida-system-t4.w25---detalii-tehnice.pdf',
  linnings_l_plates_q_pdf_label: 'placari liniare qvadruple',
  linnings_l_plates_q_dwg: 'https://media.siniat.ro/pi324800/original/placari-nida-system-t4.w25.dwg',
  linnings_l_plates_q_dwg_label: 'placari liniare qvadruple',

  linnings_nf_booklet: 'https://media.siniat.ro/pi38772/original/brosura-placari-2020-web.pdf?v=1020935976',
  linnings_nf_booklet_label: 'Brosura placari',
  linnings_nf_plates_t_pdf: 'https://media.siniat.ro/pi326367/original/nida-system-noisy-n3.f---detalii-tehnice.pdf',
  linnings_nf_plates_t_pdf_label: 'placari Noisy cu fixari triple',
  linnings_nf_plates_t_dwg: 'https://media.siniat.ro/pi326255/original/nida-system-noisy-n3.if.dwg',
  linnings_nf_plates_t_dwg_label: 'placari Noisy cu fixari triple',

  linnings_ni_booklet: 'https://media.siniat.ro/pi38772/original/brosura-placari-2020-web.pdf?v=1020935976',
  linnings_ni_booklet_label: 'Brosura placari',
  linnings_ni_plates_d_pdf: 'https://media.siniat.ro/pi326374/original/nida-system-n2.i---detalii-tehnice.pdf',
  linnings_ni_plates_d_pdf_label: 'placari Noisy independente duble',
  linnings_ni_plates_d_dwg: 'https://media.siniat.ro/pi326369/original/nida-system-noisy-n2.i---detalii-cad.dwg',
  linnings_ni_plates_d_dwg_label: 'placari Noisy independente duble',
  linnings_ni_plates_t_pdf: 'https://media.siniat.ro/pi326373/original/nida-system-n3.i---detalii-tehnice.pdf',
  linnings_ni_plates_t_pdf_label: 'placari Noisy independente triple',
  linnings_ni_plates_t_dwg: 'https://media.siniat.ro/pi326370/original/nida-system-n3.if---detalii-cad.dwg?v=869811517',
  linnings_ni_plates_t_dwg_label: 'placari Noisy independente triple',

  linnings_nuu_booklet: 'https://media.siniat.ro/pi38772/original/brosura-placari-2020-web.pdf?v=1020935976',
  linnings_nuu_booklet_label: 'Brosura placari',
  linnings_nuu_plates_d_pdf: 'https://media.siniat.ro/pi323724/original/placari-nida-system-t2.uu---detalii-tehnice.pdf',
  linnings_nuu_plates_d_pdf_label: 'placari Noisy UU duble',
  linnings_nuu_plates_d_dwg: 'https://media.siniat.ro/pi323722/original/placare-nida-system-t.2.uu.dwg',
  linnings_nuu_plates_d_dwg_label: 'placari Noisy UU duble',
  linnings_nuu_plates_t_pdf: 'https://media.siniat.ro/pi323725/original/placari-nida-system-t3.uu---detalii-tehnice.pdf',
  linnings_nuu_plates_t_pdf_label: 'placari Noisy UU triple',
  linnings_nuu_plates_t_dwg: 'https://media.siniat.ro/pi323723/original/placare-nida-system-t.3.uu.dwg',
  linnings_nuu_plates_t_dwg_label: 'placari Noisy UU triple',

  // ceilings

  ceilings_s_booklet: 'https://media.siniat.ro/pi117053',
  ceilings_s_booklet_label: 'Brosura plafoane',

  ceilings_s_plates_s_profile_s_support_1_pdf: 'https://media.siniat.ro/pi687773',
  ceilings_s_plates_s_profile_s_support_1_pdf_label: 'plafoane suspendate  simple structura simpla  cu brida',
  ceilings_s_plates_s_profile_s_support_1_dwg: 'https://media.siniat.ro/pi687767/original/-1358003426/p1.s1.cd-ud.br-039b.dwg',
  ceilings_s_plates_s_profile_s_support_1_dwg_label: 'plafoane suspendate  simple structura simpla  cu brida',
  ceilings_s_plates_s_profile_s_support_2_pdf: 'https://media.siniat.ro/pi687774',
  ceilings_s_plates_s_profile_s_support_2_pdf_label: 'plafoane suspendate  simple structura simpla  cu tirant',
  ceilings_s_plates_s_profile_s_support_2_dwg: 'https://media.siniat.ro/pi687772/original/-1358003426/p1.s1.cd-ud.t-039b.dwg',
  ceilings_s_plates_s_profile_s_support_2_dwg_label: 'plafoane suspendate  simple structura simpla  cu tirant',
  ceilings_s_plates_s_profile_s_support_3_pdf: 'https://media.siniat.ro/pi687775',
  ceilings_s_plates_s_profile_s_support_3_pdf_label: 'plafoane suspendate  simple structura simpla cu nonius',
  ceilings_s_plates_s_profile_s_support_3_dwg: 'https://media.siniat.ro/pi687769/original/-1358003426/p1.s1.cd-ud.n-039b.dwg',
  ceilings_s_plates_s_profile_s_support_3_dwg_label: 'plafoane suspendate  simple structura simpla  cu nonius',
  // ceilings_s_plates_s_profile_s_support_4_pdf: '',
  // ceilings_s_plates_s_profile_s_support_4_pdf_label: '',
  // ceilings_s_plates_s_profile_s_support_4_dwg: '',
  // ceilings_s_plates_s_profile_s_support_4_dwg_label: '',
  ceilings_s_plates_s_profile_s_support_6_pdf: 'https://media.siniat.ro/pi687771',
  ceilings_s_plates_s_profile_s_support_6_pdf_label: 'plafoane suspendate  simple structura simpla  cu brida acustica',
  ceilings_s_plates_s_profile_s_support_6_dwg: 'https://media.siniat.ro/pi687780/original/-1358003426/p1.s2.cd-ud.br-039b.dwg',
  ceilings_s_plates_s_profile_s_support_6_dwg_label: 'plafoane suspendate  simple structura simpla  cu brida acustica',
  // ceilings_s_plates_s_profile_s_support_5_pdf: '',
  // ceilings_s_plates_s_profile_s_support_5_pdf_label: '',
  // ceilings_s_plates_s_profile_s_support_5_dwg: 'https://media.siniat.ro/pi38813/original/cad-plafon-suspendat-nida-system-p1.s1.cd-ud.rl.dwg',
  // ceilings_s_plates_s_profile_s_support_5_dwg_label: 'plafoane suspendate  simple structura simpla  cu racord lemn',
  ceilings_s_plates_s_profile_d_support_1_pdf: 'https://media.siniat.ro/pi687779',
  ceilings_s_plates_s_profile_d_support_1_pdf_label: 'plafoane suspendate   structura dubla cu brida',
  ceilings_s_plates_s_profile_d_support_1_dwg: 'https://media.siniat.ro/pi687780/original/-1358003426/p1.s2.cd-ud.br-039b.dwg',
  ceilings_s_plates_s_profile_d_support_1_dwg_label: 'plafoane suspendate   structura dubla cu brida',
  ceilings_s_plates_s_profile_d_support_2_pdf: 'https://media.siniat.ro/pi687784',
  ceilings_s_plates_s_profile_d_support_2_pdf_label: 'plafoane suspendate   structura dubla cu tirant',
  ceilings_s_plates_s_profile_d_support_2_dwg: 'https://media.siniat.ro/pi687781/original/-1358003426/p1.s2.cd-ud.t-039b.dwg',
  ceilings_s_plates_s_profile_d_support_2_dwg_label: 'plafoane suspendate   structura dubla cu tirant',
  ceilings_s_plates_s_profile_d_support_3_pdf: 'https://media.siniat.ro/pi687777',
  ceilings_s_plates_s_profile_d_support_3_pdf_label: 'plafoane suspendate   structura dubla cu nonius',
  ceilings_s_plates_s_profile_d_support_3_dwg: 'https://media.siniat.ro/pi687778/original/-1358003426/p1.s2.cd-ud.n-039b.dwg',
  ceilings_s_plates_s_profile_d_support_3_dwg_label: 'plafoane suspendate   structura dubla cu nonius',
  ceilings_s_plates_s_profile_d_support_4_pdf: 'https://media.siniat.ro/pi687861',
  ceilings_s_plates_s_profile_d_support_4_pdf_label: 'plafoane suspendate   structura dubla cu tija M8',
  ceilings_s_plates_s_profile_d_support_4_dwg: 'https://media.siniat.ro/pi687856/original/-1358003426/p1.s2.ua-cd-039b.dwg',
  ceilings_s_plates_s_profile_d_support_4_dwg_label: 'plafoane suspendate   structura dubla cu tija M8',
  ceilings_s_plates_s_profile_d_support_6_pdf: 'https://www.siniat.ro/ro-ro/produse-sisteme/sisteme-gips-carton/tavane-gips-carton/?filter=numberOfLayersDescription.eq.simple/framework.eq.single/fixingType.eq.acousticbracket',
  ceilings_s_plates_s_profile_d_support_6_pdf_label: 'plafoane suspendate   structura dubla cu brida acustica',
  ceilings_s_plates_s_profile_d_support_6_dwg: 'https://media.siniat.ro/pi687780/original/-1358003426/p1.s2.cd-ud.br-039b.dwg',
  ceilings_s_plates_s_profile_d_support_6_dwg_label: 'plafoane suspendate   structura dubla cu brida acustica',
  // ceilings_s_plates_s_profile_d_support_5_pdf: '',
  // ceilings_s_plates_s_profile_d_support_5_pdf_label: '',
  // ceilings_s_plates_s_profile_d_support_5_dwg: '',
  // ceilings_s_plates_s_profile_d_support_5_dwg_label: '',

  ceilings_s_plates_d_profile_s_support_1_pdf: 'https://media.siniat.ro/pi687785',
  ceilings_s_plates_d_profile_s_support_1_pdf_label: 'plafoane suspendate  duble structura simpla  cu brida',
  ceilings_s_plates_d_profile_s_support_1_dwg: 'https://media.siniat.ro/pi687783/original/-1358003426/p2.s1.cd-ud.br-039b.dwg',
  ceilings_s_plates_d_profile_s_support_1_dwg_label: 'plafoane suspendate  duble structura simpla  cu brida',
  ceilings_s_plates_d_profile_s_support_2_pdf: 'https://media.siniat.ro/pi687793',
  ceilings_s_plates_d_profile_s_support_2_pdf_label: 'plafoane suspendate  duble structura simpla  cu tirant',
  ceilings_s_plates_d_profile_s_support_2_dwg: 'https://media.siniat.ro/pi687791/original/-1358003426/p2.s1.cd-ud.t-039b.dwg',
  ceilings_s_plates_d_profile_s_support_2_dwg_label: 'plafoane suspendate  duble structura simpla  cu tirant',
  ceilings_s_plates_d_profile_s_support_3_pdf: 'https://media.siniat.ro/pi687788',
  ceilings_s_plates_d_profile_s_support_3_pdf_label: 'plafoane suspendate  duble structura simpla  cu nonius',
  ceilings_s_plates_d_profile_s_support_3_dwg: 'https://media.siniat.ro/pi687787/original/-1358003426/p2.s1.cd-ud.n.a-b-039b.dwg',
  ceilings_s_plates_d_profile_s_support_3_dwg_label: 'plafoane suspendate  duble structura simpla  cu nonius',
  // ceilings_s_plates_d_profile_s_support_4_pdf: '',
  // ceilings_s_plates_d_profile_s_support_4_pdf_label: '',
  // ceilings_s_plates_d_profile_s_support_4_dwg: '',
  // ceilings_s_plates_d_profile_s_support_4_dwg_label: '',
  ceilings_s_plates_d_profile_s_support_6_pdf: 'https://media.siniat.ro/pi687786',
  ceilings_s_plates_d_profile_s_support_6_pdf_label: 'plafoane suspendate  duble structura simpla  cu brida acustica',
  ceilings_s_plates_d_profile_s_support_6_dwg: 'https://media.siniat.ro/pi687782/original/-1358003426/p2.s1.cd-ud.ba-039b.dwg',
  ceilings_s_plates_d_profile_s_support_6_dwg_label: 'plafoane suspendate  duble structura simpla  cu brida acustica',
  // ceilings_s_plates_d_profile_s_support_5_pdf: '',
  // ceilings_s_plates_d_profile_s_support_5_pdf_label: '',
  // ceilings_s_plates_d_profile_s_support_5_dwg: 'https://media.siniat.ro/pi38825/original/cad-plafon-suspendat-nida-system-p2.s1.cd-ud.rl.dwg',
  // ceilings_s_plates_d_profile_s_support_5_dwg_label: 'plafoane suspendate  duble structura simpla  cu racord lemn',
  ceilings_s_plates_d_profile_d_support_1_pdf: 'https://media.siniat.ro/pi687797',
  ceilings_s_plates_d_profile_d_support_1_pdf_label: 'plafoane suspendate  duble structura dubla cu brida',
  ceilings_s_plates_d_profile_d_support_1_dwg: 'https://media.siniat.ro/pi687795/original/-1358003426/p2.s2.cd-ud.br-039b.dwg',
  ceilings_s_plates_d_profile_d_support_1_dwg_label: 'plafoane suspendate  duble structura dubla cu brida',
  ceilings_s_plates_d_profile_d_support_2_pdf: 'https://media.siniat.ro/pi687803',
  ceilings_s_plates_d_profile_d_support_2_pdf_label: 'plafoane suspendate  duble structura dubla cu tirant',
  ceilings_s_plates_d_profile_d_support_2_dwg: 'https://media.siniat.ro/pi687800/original/-1358003426/p2.s2.cd-ud.t-039b.dwg',
  ceilings_s_plates_d_profile_d_support_2_dwg_label: 'plafoane suspendate  duble structura dubla cu tirant',
  ceilings_s_plates_d_profile_d_support_3_pdf: 'https://media.siniat.ro/pi687802',
  ceilings_s_plates_d_profile_d_support_3_pdf_label: 'plafoane suspendate  duble structura dubla cu nonius',
  ceilings_s_plates_d_profile_d_support_3_dwg: 'https://media.siniat.ro/pi687799/original/-1358003426/p2.s2.cd-ud.n-039b.dwg',
  ceilings_s_plates_d_profile_d_support_3_dwg_label: 'plafoane suspendate  duble structura dubla cu nonius',
  ceilings_s_plates_d_profile_d_support_4_pdf: 'https://media.siniat.ro/pi687862',
  ceilings_s_plates_d_profile_d_support_4_pdf_label: 'plafoane suspendate  duble structura dubla cu tija M8',
  ceilings_s_plates_d_profile_d_support_4_dwg: 'https://media.siniat.ro/pi687857/original/-1358003426/p2.s2.ua-cd-039b.dwg',
  ceilings_s_plates_d_profile_d_support_4_dwg_label: 'plafoane suspendate  duble structura dubla cu tija M8',
  ceilings_s_plates_d_profile_d_support_6_pdf: 'https://www.siniat.ro/ro-ro/produse-sisteme/sisteme-gips-carton/tavane-gips-carton/?filter=numberOfLayersDescription.eq.double/fixingType.eq.acousticbracket',
  ceilings_s_plates_d_profile_d_support_6_pdf_label: 'plafoane suspendate  duble structura dubla cu brida acustica',
  ceilings_s_plates_d_profile_d_support_6_dwg: 'https://media.siniat.ro/pi687782/original/-1358003426/p2.s1.cd-ud.ba-039b.dwg',
  ceilings_s_plates_d_profile_d_support_6_dwg_label: 'plafoane suspendate  duble structura dubla cu brida acustica',
  // ceilings_s_plates_d_profile_d_support_5_pdf: '',
  // ceilings_s_plates_d_profile_d_support_5_pdf_label: '',
  // ceilings_s_plates_d_profile_d_support_5_dwg: '',
  // ceilings_s_plates_d_profile_d_support_5_dwg_label: '',

  ceilings_s_plates_t_profile_s_support_1_pdf: 'https://media.siniat.ro/pi687804',
  ceilings_s_plates_t_profile_s_support_1_pdf_label: 'plafoane suspendate  triple structura simpla  cu brida',
  ceilings_s_plates_t_profile_s_support_1_dwg: 'https://media.siniat.ro/pi687801/original/-1358003426/p3.s1.cd-ud.br-039b.dwg',
  ceilings_s_plates_t_profile_s_support_1_dwg_label: 'plafoane suspendate  triple structura simpla  cu brida',
  ceilings_s_plates_t_profile_s_support_3_pdf: 'https://media.siniat.ro/pi687805',
  ceilings_s_plates_t_profile_s_support_3_pdf_label: 'plafoane suspendate  triple structura simpla  cu nonius',
  ceilings_s_plates_t_profile_s_support_3_dwg: 'https://media.siniat.ro/pi687815/original/-1358003426/p3.s1.cd-ud.n-039b.dwg',
  ceilings_s_plates_t_profile_s_support_3_dwg_label: 'plafoane suspendate  triple structura simpla  cu nonius',
  // ceilings_s_plates_t_profile_s_support_4_pdf: '',
  // ceilings_s_plates_t_profile_s_support_4_pdf_label: '',
  // ceilings_s_plates_t_profile_s_support_4_dwg: '',
  // ceilings_s_plates_t_profile_s_support_4_dwg_label: '',
  ceilings_s_plates_t_profile_d_support_1_pdf: 'https://media.siniat.ro/pi687813',
  ceilings_s_plates_t_profile_d_support_1_pdf_label: 'plafoane suspendate  triple structura dubla  cu brida',
  ceilings_s_plates_t_profile_d_support_1_dwg: 'https://media.siniat.ro/pi687806/original/-1358003426/p3.s2.cd-ud.br-039b.dwg',
  ceilings_s_plates_t_profile_d_support_1_dwg_label: 'plafoane suspendate   structura dubla  cu brida',
  ceilings_s_plates_t_profile_d_support_3_pdf: 'https://media.siniat.ro/pi687808',
  ceilings_s_plates_t_profile_d_support_3_pdf_label: 'plafoane suspendate  triple structura dubla  cu nonius',
  ceilings_s_plates_t_profile_d_support_3_dwg: 'https://media.siniat.ro/pi687807/original/-1358003426/p3.s2.cd-ud.n-039b.dwg',
  ceilings_s_plates_t_profile_d_support_3_dwg_label: 'plafoane suspendate   structura dubla  cu nonius',
  ceilings_s_plates_t_profile_d_support_4_pdf: 'https://media.siniat.ro/pi687863',
  ceilings_s_plates_t_profile_d_support_4_pdf_label: 'plafoane suspendate  triple structura dubla  cu tija M8',
  ceilings_s_plates_t_profile_d_support_4_dwg: 'https://media.siniat.ro/pi687858/original/-1358003426/p3.s2.ua-cd-039b.dwg',
  ceilings_s_plates_t_profile_d_support_4_dwg_label: 'plafoane suspendate   structura dubla  cu Detalii DWG plafoane suspendate   structura dubla  cu nonius',

  ceilings_s_plates_q_profile_s_support_1_pdf: 'https://media.siniat.ro/pi687814',
  ceilings_s_plates_q_profile_s_support_1_pdf_label: 'plafoane suspendate  qvadruple structura simpla  cu brida',
  ceilings_s_plates_q_profile_s_support_1_dwg: 'https://media.siniat.ro/pi687809/original/-1358003426/p4.s1.cd-ud.br-039b.dwg',
  ceilings_s_plates_q_profile_s_support_1_dwg_label: 'plafoane suspendate  qvadruple structura simpla  cu brida',
  ceilings_s_plates_q_profile_s_support_3_pdf: 'https://media.siniat.ro/pi687811',
  ceilings_s_plates_q_profile_s_support_3_pdf_label: 'plafoane suspendate  qvadruple structura simpla  cu nonius',
  ceilings_s_plates_q_profile_s_support_3_dwg: 'https://media.siniat.ro/pi687817/original/-1358003426/p4.s1.cd-ud.n-039b.dwg',
  ceilings_s_plates_q_profile_s_support_3_dwg_label: 'plafoane suspendate  qvadruple structura simpla  cu nonius',
  // ceilings_s_plates_q_profile_s_support_4_pdf: '',
  // ceilings_s_plates_q_profile_s_support_4_pdf_label: '',
  // ceilings_s_plates_q_profile_s_support_4_dwg: '',
  // ceilings_s_plates_q_profile_s_support_4_dwg_label: '',
  ceilings_s_plates_q_profile_d_support_1_pdf: 'https://media.siniat.ro/pi687810',
  ceilings_s_plates_q_profile_d_support_1_pdf_label: 'plafoane suspendate  qvadruple structura dubla  cu brida',
  ceilings_s_plates_q_profile_d_support_1_dwg: 'https://media.siniat.ro/pi687816/original/-1358003426/p4.s2.cd-ud.br-039b.dwg',
  ceilings_s_plates_q_profile_d_support_1_dwg_label: 'plafoane suspendate  qvadruple structura dubla  cu brida',
  ceilings_s_plates_q_profile_d_support_3_pdf: 'https://media.siniat.ro/pi687820',
  ceilings_s_plates_q_profile_d_support_3_pdf_label: 'plafoane suspendate  qvadruple structura dubla  cu nonius',
  ceilings_s_plates_q_profile_d_support_3_dwg: 'https://media.siniat.ro/pi687812/original/-1358003426/p4.s2.cd-ud.n-039b.dwg',
  ceilings_s_plates_q_profile_d_support_3_dwg_label: 'plafoane suspendate  qvadruple structura dubla  cu nonius',
  ceilings_s_plates_q_profile_d_support_4_pdf: 'https://media.siniat.ro/pi687819',
  ceilings_s_plates_q_profile_d_support_4_pdf_label: 'plafoane suspendate  qvadruple structura dubla  cu tija M8',
  ceilings_s_plates_q_profile_d_support_4_dwg: 'https://media.siniat.ro/pi687818/original/-1358003426/p4.s2.ua-cd-039b.dwg',
  ceilings_s_plates_q_profile_d_support_4_dwg_label: 'plafoane suspendate  qvadruple structura dubla  cu tija M8',
}
